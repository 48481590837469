import api from "../axios_service.js";

export async function getPermissoes(filtros) {
  const response = await api.get(`admin/permissoes${filtros ? filtros : ""}`);
  return response.data.permissoes;
}

export async function getPermissoesNoTree(filtros) {
  const response = await api.get(`admin/permissoes-notree${filtros ? filtros : ""}`);
  return response.data.permissoes;
}

export async function getPermissao(id) {
  const response = await api.get(`admin/permissoes/${id}`);
  return response.data.permissao;
}

export function putPermissao(id, permissao) {
  let body = {};
  for (let key in permissao) {
    body[key] = permissao[key];
  }
  return api.put(`admin/permissoes/${id}`, body);
}

export function postPermissao(permissao) {
  let body = {};
  for (let key in permissao) {
    body[key] = permissao[key];
  }
  return api.post("admin/permissoes/add", body);
}
